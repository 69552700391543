<template>
  <!-- 操作日志 -->
  <div>
    <!-- 顶部输入框 -->
    <div class="search">
      <el-date-picker
        v-model="value"
        type="daterange"
        range-separator="至"
        start-placeholder="开始日期"
        end-placeholder="结束日期"
        value-format="yyyy-MM-dd"
        @change="pickerChange"
      >
      </el-date-picker>
    </div>
    <!-- 中间表格区 -->
    <div>
      <el-table border :data="data">
        <el-table-column label="序号" prop="logId" align="center"></el-table-column>
        <el-table-column label="操作时间" prop="logTime" align="center"></el-table-column>
        <el-table-column label="系统角色" prop="adminName" align="center"></el-table-column>
        <el-table-column label="用户名" prop="adminId" align="center"></el-table-column>
        <el-table-column label="手机号码" prop="logContent" align="center"></el-table-column>
        <el-table-column label="操作人IP" prop="ip" align="center"></el-table-column>
        <el-table-column label="操作模块" prop="url" align="center"></el-table-column>
      </el-table>
    </div>

    <!-- 底部分页 -->
    <div class="pagination">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="currentPage"
        :page-sizes="[20, 30, 50]"
        :page-size="pageNumber"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      value: '', // 时间
      data: null,
      total: null,
      currentPage: 1,
      pageNumber: 20,
      form: {
        pageNumber: 1, // 当前页
        pageSize: 20 // 分页大小
      }
    }
  },
  created() {
    this.onLog()
  },
  methods: {
    // 获取操作日志
    onLog() {
      this.axios.get('/admin/log/getLog', this.form).then(res => {
        if (res.data.code == 200) {
          console.log(res)
          this.data = res.data.data.records
          this.total = res.data.data.total
          this.currentPage = res.data.data.current
        }
      })
    },
    // 时间
    pickerChange(e) {
      if (e) {
        this.form.startTime = e[0]
        this.form.endTime = e[1]
      } else {
        delete this.form.startTime
        delete this.form.endTime
      }
      this.form.pageNumber = 1
      this.onLog()
    },

    // 分页
    handleSizeChange(val) {
      this.form.pageSize = val
      this.onLog()
    },
    handleCurrentChange(val) {
      this.form.pageNumber = val
      this.onLog()
    }
  }
}
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  padding: 30px 0;
}
.pagination {
  display: flex;
  justify-content: center;
  margin-top: 50px;
}
</style>
